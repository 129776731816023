import { ProductType, ProgramItem } from 'entities/system';
import { Plugin } from 'chart.js';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/ban-types
type ItemFilters = {};

type MiscFilters = {
  productType: ProductType | null;
  name: string;
  date: {
    from: string | null;
    to: string | null;
  };
};

export type Filters = ItemFilters & MiscFilters;

const itemFilters: ItemFilters = {};

const miscFilters: MiscFilters = {
  productType: null,
  name: '',
  date: {
    from: null,
    to: null,
  },
};

export const defaultFilters: Filters = {
  ...itemFilters,
  ...miscFilters,
};

// eslint-disable-next-line @typescript-eslint/ban-types
type LoadItemsOptions = {} & Filters;

export const listRequestPayload = (options: LoadItemsOptions) => {
  const date = options.date.from ? { filters: options.date } : {};
  return {
    productType: options.productType || undefined,
    name: options.name || undefined,
    ...date,
  };
};

export const normalizeChargeback = (chargeback: any): ProgramItem => ({
  ...chargeback,
});

export type CardsInfo = {
  usersNumber: number;
  merchantNumber: number;
  monthlyAuthorizationNumber: number;
  monthlySettlementNumber: number;
  notificationsSummary: NotificationsSummary | null;
  totalTransactionFor30Days: TotalTransactionFor30Days[];
  transactionByStatus: TransactionByStatus[];
  transactionByType: TransactionByType[];
  totalAuthorizationsFor30DaysDto: TotalTransactionFor30Days[];
  authorizationsByStatus: TransactionByStatus[];
  authorizationsByType: TransactionByType[];
};

export type NotificationsSummary = {
  count: number;
  notifications: Notifications[];
};

export type Notifications = {
  id: number;
  content: string;
  relationId: string;
  timeInfo: string;
  title: string;
  type: string;
};

export type TotalTransactionFor30Days = {
  transactionDate: string;
  numberOfTransactions: number;
};

export type TransactionByStatus = {
  transactionStatus: string;
  transactionStatusName?: string;
  numberOfTransactions: number;
};

export type TransactionByType = {
  transactionType: string;
  transactionTypeName?: string;
  numberOfTransactions: number;
};

export type UnmatchedTransactionItem = {
  id: string;
  cardNumber: string;
  transactionDate: string;
  transactionAmount: number;
  messageType: string;
  matchType: string;
  merchantName: string;
};

export const notificationsSummaryItem: NotificationsSummary = {
  count: 0,
  notifications: [] as Notifications[],
};

export const CardInfoItem: CardsInfo = {
  usersNumber: 0,
  monthlyAuthorizationNumber: 0,
  monthlySettlementNumber: 0,
  merchantNumber: 0,
  notificationsSummary: notificationsSummaryItem,
  totalTransactionFor30Days: [] as TotalTransactionFor30Days[],
  transactionByStatus: [] as TransactionByStatus[],
  transactionByType: [] as TransactionByType[],
  totalAuthorizationsFor30DaysDto: [] as TotalTransactionFor30Days[],
  authorizationsByStatus: [] as TransactionByStatus[],
  authorizationsByType: [] as TransactionByType[],
};

export const TransactionByStatusDataChart: TransactionByStatus[] = [
  {
    transactionStatus: 'Processed',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'Rejected',
    numberOfTransactions: 0,
  },
];

export const TransactionByTypeDataChart: TransactionByType[] = [
  {
    transactionType: 'Sale',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'Credit',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'Cash Advance',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'Sale Auth Request',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'Conditional Deposit Sale',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'Conditional Deposit Cash Advance',
    numberOfTransactions: 0,
  },
];

export const AuthorizationByStatusDataChart: TransactionByStatus[] = [
  {
    transactionStatus: 'COMPLETED',
    transactionStatusName: 'Completed',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'PENDING',
    transactionStatusName: 'Pending',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'VOIDED',
    transactionStatusName: 'Voided',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'FAILED',
    transactionStatusName: 'Failed',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'REVERSED',
    transactionStatusName: 'Reversed',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'REFUNDED',
    transactionStatusName: 'Refunded',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'DECLINED',
    transactionStatusName: 'Declined',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'SETTLED',
    transactionStatusName: 'Settled',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'REJECTED',
    transactionStatusName: 'Rejected',
    numberOfTransactions: 0,
  },
  {
    transactionStatus: 'PARTIAL_REFUNDED',
    transactionStatusName: 'Partial Refunded',
    numberOfTransactions: 0,
  },
];

export const AuthorizationByTypeDataChart: TransactionByType[] = [
  {
    transactionType: 'ACH',
    transactionTypeName: 'ACH',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'AUTHORIZATION',
    transactionTypeName: 'Authorization',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'CAPTURE',
    transactionTypeName: 'Capture',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'PURCHASE',
    transactionTypeName: 'Purchase',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'REFUND',
    transactionTypeName: 'Refund',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'REVERSAL',
    transactionTypeName: 'Reversal',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'VOID',
    transactionTypeName: 'Void',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'INCREMENTAL_AUTHORIZATION',
    transactionTypeName: 'Incremental Authorization',
    numberOfTransactions: 0,
  },
  {
    transactionType: 'PARTIAL_REVERSAL',
    transactionTypeName: 'Partial Reversal',
    numberOfTransactions: 0,
  },
];

export const transactionByPlugins: Plugin = {
  id: 'transactionBy',
  afterDraw: (chart) => {
    if (!chart.data.datasets[0].data.find((it) => it !== 0)) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;

      //in case if we need to clean all data
      // chart.clear();
      // ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        'No transactions to display for current period',
        width / 2,
        height / 2
      );
      ctx.restore();
    }
  },
};

export const formatterData = (value, context) => {
  const dataPoints = context.chart.data.datasets[0].data;
  if (!dataPoints.find((it) => it !== 0) || value === 0) {
    return '';
  }
  const label = context.chart.data.labels[context.dataIndex];
  const dataSum = (total: any, datapoint: any) => total + datapoint;
  const totalValue = dataPoints.reduce(dataSum, 0);
  const percentageValue = ((value / totalValue) * 100).toFixed(0);

  return [`${label}: ${value}`, `${percentageValue}%`];
};

export const formatterContextData = (context: any) => {
  const dataPoints = context.chart.data.datasets[0].data;
  if (!dataPoints.find((it) => it !== 0)) {
    return '';
  }
  const label = context.chart.data.labels[context.dataIndex];
  const value = context.parsed;
  const dataSum = (total: any, datapoint: any) => total + datapoint;
  const totalValue = dataPoints.reduce(dataSum, 0);
  const percentageValue = ((value / totalValue) * 100).toFixed(0);

  return [`${label}: ${value}`, `${percentageValue}%`];
};

export const startDate = moment().subtract(30, 'days').format('DD-MM-YYYY');
export const endDate = moment().subtract(1, 'days').format('DD-MM-YYYY');

export enum NotificationType {
  INFORMATIONAL = 'Informational',
  ERROR = 'Error',
  ALERT = 'Alert',
  WARNING = 'Warning',
  SUCCESS = 'Success',
  REMINDER = 'Reminder',
  SYSTEM_MAINTENANCE = 'System maintenance',
  SECURITY_ALERT = 'Security alert',
  ACTION_REQUIRED = 'Action required',
  PROMOTIONAL = 'Promotional',
}
