import { Pie } from 'react-chartjs-2';
import { Link } from '@mui/material';
import { loadAuthorizationStatusInfoItems } from '../thunks';
import {
  AuthorizationByStatusDataChart as authorizationByStatusDataChart,
  formatterContextData,
  transactionByPlugins,
} from '../utils';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getCardInfo,
  getItemsLoadingComputed,
  getTransactionStatusLoadingComputed,
} from '../selectors';
import { RectLoader } from 'components/RectLoader';
import { PeriodType } from '../type';

export const AuthorizationsStatusChartComponent = () => {
  const cardInfoItem = useAppSelector(getCardInfo);
  const itemsLoading = useAppSelector(getItemsLoadingComputed);
  const transactionStatusLoading = useAppSelector(
    getTransactionStatusLoadingComputed
  );
  const dispatch = useAppDispatch();

  const [activePeriod, setActivePeriod] = useState<PeriodType>(
    PeriodType.YESTERDAY
  );

  const authorizationByStatuses = useMemo(() => {
    if (!cardInfoItem.authorizationsByStatus.length) {
      authorizationByStatusDataChart.map((it) => (it.numberOfTransactions = 0));
    } else {
      cardInfoItem.authorizationsByStatus.map(
        (it) =>
          (authorizationByStatusDataChart.find(
            (item) =>
              item.transactionStatus.toUpperCase() ===
              it.transactionStatus.toUpperCase()
          )!.numberOfTransactions = it.numberOfTransactions)
      );
    }

    return authorizationByStatusDataChart;
  }, [cardInfoItem.authorizationsByStatus]);

  const transactionByStatusData = {
    labels: authorizationByStatuses.map((it) => it.transactionStatusName),
    datasets: [
      {
        data: authorizationByStatuses.map((it) => it.numberOfTransactions),
        backgroundColor: [
          'rgba(0,166,24,0.2)',
          'rgba(12,0,119,0.2)',
          'rgba(189,186,0,0.2)',
          'rgba(100,0,166,0.53)',
          'rgba(166,0,158,0.2)',
          'rgba(140,250,158,0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgb(2,129,21)',
          'rgb(2,129,21)',
          'rgb(2,129,21)',
          'rgb(2,129,21)',
          'rgb(2,129,21)',
          'rgb(2,129,21)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const transactionByStatusOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: formatterContextData,
        },
      },
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        color: '#000000',
        font: {
          weight: 'normal' as const,
          size: 14,
        },
        text: 'Authorizations by status',
      },
    },
  };

  const activeLinkPeriod = (period: PeriodType) =>
    activePeriod === period ? 'bold' : 'normal';

  const updateChartByPeriod = (period: PeriodType) => {
    dispatch(
      loadAuthorizationStatusInfoItems({
        dashboardChartPeriod: period,
      })
    );
    setActivePeriod(period);
  };

  return (
    <div className="col-span-8 lg:col-span-4 items-center justify-center h-auto bg-white border p-4 rounded-md box-shadow-dark">
      {itemsLoading || transactionStatusLoading ? (
        <div className="flex items-center justify-center h-full">
          <RectLoader width={150} height={28} />
        </div>
      ) : (
        <div className="flex flex-col h-full">
          <div className="h-full">
            <Pie
              data={transactionByStatusData}
              options={transactionByStatusOptions}
              plugins={[transactionByPlugins as any]}
              height={'350px'}
              width={'100%'}
            />
          </div>
          <hr className="style-hr" />
          <div className="flex flex-wrap items-center justify-between justify-items-center mx-10 mb-1">
            <div>
              <Link
                underline={'none'}
                fontWeight={activeLinkPeriod(PeriodType.YESTERDAY)}
                className="text-sm cursor-pointer"
                onClick={() => updateChartByPeriod(PeriodType.YESTERDAY)}
              >
                {'Yesterday'}
              </Link>
            </div>
            <div>
              <Link
                underline={'none'}
                fontWeight={activeLinkPeriod(PeriodType.LAST_WEEK)}
                className="text-sm cursor-pointer"
                onClick={() => updateChartByPeriod(PeriodType.LAST_WEEK)}
              >
                {'Last Week'}
              </Link>
            </div>
            <div>
              <Link
                underline={'none'}
                fontWeight={activeLinkPeriod(PeriodType.LAST_MONTH)}
                className="text-sm cursor-pointer"
                onClick={() => updateChartByPeriod(PeriodType.LAST_MONTH)}
              >
                {'Last 30 days'}
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
