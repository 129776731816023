import { PageHeader } from 'components/PageHeader';
import { useHistory, useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { fetchDetails } from '../thunks';
import {
  getDetailsLoading,
  getItemDetail,
  getItemsNotFound,
} from '../selectors';
import { Button } from '@mui/material';
import { ItemView } from 'components/grid/ItemView';
import { mdiMathLog } from '@mdi/js';
import Icon from '@mdi/react';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { JsonViewer } from '@textea/json-viewer';
import { RectLoader } from 'components/RectLoader';

export const ApiLogsDetailsComponent = () => {
  const dispatch = useAppDispatch();
  const itemNotFound = useSelector(getItemsNotFound);
  const isDetailsLoading = useSelector(getDetailsLoading);
  const itemDetail = useSelector(getItemDetail);
  const history: any = useHistory();
  const { id } = useParams<{ id: string }>();
  const apiLogId = Base64.decode(id);
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);

  const timeFormat = (value: string | null) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);

  useEffect(() => {
    dispatch(fetchDetails({ id: apiLogId }));
  }, [apiLogId, dispatch]);

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title="API Logs" />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
            <Button onClick={() => history.goBack()} variant="outlined">
              Back
            </Button>
          </div>
          {itemNotFound && (
            <div className="flex col-span-8 l2g:col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="w-full">
                <div className="-mt-8 w-full sm:w-auto">
                  <div className="flex flex-col items-center justify-center justify-items-center w-40 h-16 rounded-md ml-3 box-shadow-nero box-bg-nero-300">
                    <div className="border rounded-lg">
                      <Icon path={mdiMathLog} size={1.5} color="#fff" />
                    </div>
                    <div className="text-xs text-white ml-1">No Details</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                  <div>No such log</div>
                </div>
              </div>
            </div>
          )}
          {isDetailsLoading && (
            <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
              <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                <div className="w-full">
                  <RectLoader width={150} height={18} />
                </div>
              </div>
            </div>
          )}
          {!isDetailsLoading && !itemNotFound && (
            <>
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="flex flex-wrap justify-between">
                    <div className="-mt-8 w-full sm:w-auto">
                      <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-nepal box-shadow-nepal">
                        <div className="border rounded-lg">
                          <Icon path={mdiMathLog} size={1.5} color="#fff" />
                        </div>
                        <div className="text-xs text-white ml-1">Details</div>
                      </div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 sm:p-3">
                    <ItemView
                      labelName="Merchant Name "
                      value={itemDetail.merchantName}
                    />
                    <ItemView
                      labelName="Program Name"
                      value={itemDetail.programName}
                    />
                    <ItemView
                      labelName="Client Name"
                      value={itemDetail.clientName}
                    />
                    {itemDetail.transactionId ? (
                      <div className="col-span-2">
                        <ItemView
                          labelName="Transaction Id"
                          value={itemDetail.transactionId}
                        />
                      </div>
                    ) : (
                      <ItemView
                        labelName="Event Id"
                        isNotSet
                        isUnknown
                        value={itemDetail.eventId}
                      />
                    )}
                    {itemDetail.transactionId ? (
                      <ItemView
                        labelName="Transaction Type "
                        value={itemDetail.eventType}
                      />
                    ) : (
                      <ItemView
                        labelName="Event Type"
                        isNotSet
                        isUnknown
                        value={itemDetail.eventType}
                      />
                    )}
                    <ItemView
                      labelName="Api Group"
                      isNotSet
                      isUnknown
                      value={itemDetail.apiGroup?.replace('_', ' ')}
                    />
                    <ItemView labelName="Amount" value={itemDetail.amount} />
                    <ItemView
                      labelName="Terminal Id"
                      value={itemDetail.terminalId}
                    />
                    <ItemView
                      labelName="Card Holder Ip"
                      value={itemDetail.cardHolderIp}
                    />
                    <ItemView
                      labelName="Created At"
                      value={timeFormat(itemDetail.createdTime)}
                    />
                    <ItemView
                      labelName="Response Time"
                      value={timeFormat(itemDetail.responseTime)}
                    />
                    <div className="col-span-2">
                      <ItemView
                        labelName="Reference"
                        value={
                          itemDetail.reference ||
                          itemDetail.request?.reference ||
                          itemDetail.response?.reference ||
                          ''
                        }
                      />
                    </div>
                    <div className="col-span-2">
                      <ItemView labelName="Error" value={itemDetail.error} />
                    </div>
                  </div>
                  <div className="sm:m-4 sm:p-3">
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {itemDetail.request ? 'Request' : 'No Request'}
                      </AccordionSummary>
                      {itemDetail.request ? (
                        <AccordionDetails>
                          <JsonViewer
                            value={itemDetail.request}
                            theme="dark"
                            rootName={false}
                            displayDataTypes={false}
                            maxDisplayLength={10000}
                          />
                        </AccordionDetails>
                      ) : null}
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        Response
                      </AccordionSummary>
                      <AccordionDetails>
                        <>
                          <JsonViewer
                            value={itemDetail.response}
                            theme="dark"
                            rootName={false}
                            displayDataTypes={false}
                            maxDisplayLength={10000}
                          />
                        </>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
