import { Input } from 'components/inputs';
import { useState } from 'react';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { MerchantCreateSteps } from './MerchantCreateSteps';
import { MerchantAccountHolderItem } from 'entities/system';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getMerchantAccountHolderItemStatus } from '../selectors';
import { setMerchantAccountHolderCreate } from '../merchantsSlice';
import { DatePickerDropDown } from 'components/DatePickerDropDown';
import { countryCodeList } from 'api/merchants';
import { emailPattern } from 'validation/patterns';
import { SelectNoController } from 'components/inputs/SelectNoController';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import { dateTimeFormatUtil } from 'components/dateTimeFormatUtil';

export const MerchantAccountHolderCreateComponent = () => {
  const dispatch = useAppDispatch();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string | null) =>
    dateTimeFormatUtil(value, dateFormat, dateZone);
  const merchantAccountItem = useAppSelector(
    getMerchantAccountHolderItemStatus
  );
  const [accountInfo, setAccountInfo] =
    useState<MerchantAccountHolderItem>(merchantAccountItem);

  const setAccountHolderValue = (value: any, name: string) => {
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });
  };

  const clearAccountHolderBirthdate = () => {
    setAccountInfo({
      ...accountInfo,
      accountHolderBirthdate: '',
    });
  };

  const setAccountHolder = () =>
    dispatch(setMerchantAccountHolderCreate(accountInfo));

  const isDisabled =
    !accountInfo.accountHolderFirstName.trim().length ||
    !(accountInfo.accountHolderFirstName.trim().length < 50) ||
    !accountInfo.accountHolderLastName.trim().length ||
    !(accountInfo.accountHolderLastName.trim().length < 50) ||
    !accountInfo.legalEntityType.trim().length ||
    !(accountInfo.legalEntityType.trim().length < 50) ||
    !accountInfo.accountHolderBillingAddressCity.trim().length ||
    !(accountInfo.accountHolderBillingAddressCity.trim().length < 80) ||
    !accountInfo.accountHolderBillingAddressPostalCode.trim().length ||
    !(accountInfo.accountHolderBillingAddressPostalCode.trim().length < 50) ||
    !accountInfo.accountHolderBillingAddressSubDivisionCode.trim().length ||
    !(
      accountInfo.accountHolderBillingAddressSubDivisionCode.trim().length < 20
    ) ||
    !accountInfo.accountHolderPhone.trim().length ||
    !(accountInfo.accountHolderPhone.trim().length < 50) ||
    !accountInfo.accountHolderCountryCode ||
    !accountInfo.accountHolderBillingAddressCountryCode ||
    !emailPattern.test(accountInfo.accountHolderEmail) ||
    !accountInfo.accountHolderBillingAddressAddressLine1.trim().length ||
    !accountInfo.accountHolderBirthdate.trim().length;

  return (
    <>
      <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-4 3xl:grid-cols-4 items-center justify-items-start my-4 sm:m-4 sm:p-3">
        <div className="w-full ">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Country Code"
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            placeholder="Select Country Code"
            name="accountHolderCountryCode"
            height="2.2rem"
            onChange={(val) =>
              setAccountHolderValue(val, 'accountHolderCountryCode')
            }
            loadOptions={countryCodeList}
            value={
              accountInfo.accountHolderCountryCode?.value
                ? accountInfo.accountHolderCountryCode
                : null
            }
            required
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="First Name" />}
            placeholder="First Name"
            labelFontClassName="text-sm font-normal"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'accountHolderFirstName'
              )
            }
            defaultValue={merchantAccountItem.accountHolderFirstName}
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Last Name" />}
            placeholder="Last Name"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'accountHolderLastName'
              )
            }
            defaultValue={merchantAccountItem.accountHolderLastName}
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Email" />}
            placeholder="Email"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'accountHolderEmail'
              )
            }
            defaultValue={merchantAccountItem.accountHolderEmail}
            labelFontClassName="text-sm font-normal"
            hasError={
              accountInfo.accountHolderEmail !== '' &&
              !emailPattern.test(accountInfo.accountHolderEmail)
            }
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Phone" />}
            placeholder="Phone"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'accountHolderPhone'
              )
            }
            defaultValue={merchantAccountItem.accountHolderPhone}
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <DatePickerDropDown
            title={'Select Date'}
            label={<RequiredFieldComponent labelText="Birthdate" />}
            labelClass="tracking-wide text-gray-900 text-sm font-normal"
            placeholder={'Select Birthdate'}
            generationDate={accountInfo.accountHolderBirthdate}
            onClearFilter={clearAccountHolderBirthdate}
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            }
            inputHeightClassName="h-9"
            handleDateChange={(date) =>
              setAccountHolderValue(timeFormat(date), 'accountHolderBirthdate')
            }
            isMonthYearPicker={false}
            isBirthdatePicker={true}
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Billing Address Country Code"
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            placeholder="Select Country Code"
            name="accountHolderBillingAddressCountryCode"
            onChange={(val) =>
              setAccountHolderValue(
                val,
                'accountHolderBillingAddressCountryCode'
              )
            }
            loadOptions={countryCodeList}
            value={
              accountInfo.accountHolderBillingAddressCountryCode?.value
                ? accountInfo.accountHolderBillingAddressCountryCode
                : null
            }
            required
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent labelText="Billing Address Sub Division Code" />
            }
            placeholder="Billing Address Sub Division Code"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'accountHolderBillingAddressSubDivisionCode'
              )
            }
            hasError={
              accountInfo.accountHolderBillingAddressSubDivisionCode?.length >
              20
            }
            defaultValue={
              merchantAccountItem.accountHolderBillingAddressSubDivisionCode
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Billing Address City" />}
            placeholder="Billing Address City"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'accountHolderBillingAddressCity'
              )
            }
            defaultValue={merchantAccountItem.accountHolderBillingAddressCity}
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent labelText="Billing Address Postal Code" />
            }
            placeholder="Billing Address Postal Code"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'accountHolderBillingAddressPostalCode'
              )
            }
            defaultValue={
              merchantAccountItem.accountHolderBillingAddressPostalCode
            }
            hasError={
              accountInfo.accountHolderBillingAddressPostalCode?.length > 50
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent labelText="Billing Address Address Line1" />
            }
            placeholder="Billing Address Address Line1"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'accountHolderBillingAddressAddressLine1'
              )
            }
            defaultValue={
              merchantAccountItem.accountHolderBillingAddressAddressLine1
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Legal Entity" />}
            placeholder="Legal Entity"
            onChange={(val) =>
              setAccountHolderValue(
                val.currentTarget.value.trim(),
                'legalEntityType'
              )
            }
            defaultValue={merchantAccountItem.legalEntityType}
            labelFontClassName="text-sm font-normal"
          />
        </div>
      </div>
      <MerchantCreateSteps
        isNextStepDisabled={isDisabled}
        onClick={setAccountHolder}
      />
    </>
  );
};
